.darshanamBody {
  justify-content: center;
  display: flex;
  &:focus {
    outline: none !important;
    border: none !important;
  }
  .darshanamBodyCard {
    width: 100%;
    max-width: 1200px;
    &:focus {
      outline: none !important;
      border: none !important;
    }
  }
  .darshanamTitle {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .darshanamBookingDate,
  .darshanamTickets {
    font-weight: bold;
    margin-left: 3px;
    margin-bottom: 5px;
  }
  .resultsTitle,
  .paymentTitle {
    text-align: left;
    font-weight: bold;
    margin-left: 5px;
    margin-bottom: 20px;
  }
  .paymentTitle {
    margin-left: 0px !important;
  }
  .paymentDetailsButton {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .resultsRow {
    overflow: auto;
    max-height: 350px;
    margin-right: 20px !important;
  }

  .resultsRow::-webkit-scrollbar {
    width: 2px;
  }
  .resultsRow::-webkit-scrollbar-track {
    background-color: #f0f2f5;
    border-radius: 50%;
  }
  .resultsRow::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px #f68121;
    border-radius: 50%;
  }
  .darshanamCard {
    text-transform: capitalize;
    border: 2px solid #f0f2f5;
    border-radius: 15px;
  }
  .ticketCount {
    width: 30%;
    justify-content: center;
    font-size: 20px;
    color: red;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  .totalAmountTitle {
    width: 50%;
    font-weight: bold;
    font-size: 25px;
    text-align: left;
  }
  .totalAmount {
    width: 50%;
    text-align: right;
    font-size: 25px;
  }
}
