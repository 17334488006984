@mixin container($minWidth, $maxWidth) {
  min-width: $minWidth;
  max-width: $maxWidth;
}
.sd-select {
  width: 250px;
}
.sd-select-dropdown-1 {
  @include container(250px, 370px);
  width: 100% !important;
}
.sd-select-dropdown-2 {
  @include container(250px, 250px);
  width: 100% !important;
}
