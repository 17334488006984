.menu_sider::-webkit-scrollbar {
  display: none;
}

.menu_sider {
  padding-bottom: 150px !important;
  height: 100%;
  overflow-y: scroll !important;
}
.ant-menu:last-child {
  padding-bottom: 70px;
}
