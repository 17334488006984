@media only screen and (max-width: 600px) {
  .mobile-responsive-width {
    width: 100%;
    .mobile-button-width {
      width: 96%;
    }
  }
}
.sd-toll-gate {
  .toll-gate-card {
    border: 1px solid rgb(240, 242, 245) !important;
    border-bottom: none;

    padding: 3px;
    .ant-card-body {
      padding: 7px;
    }
  }
  .toll-gate-card:last-child {
    border-bottom: 1px solid rgb(240, 242, 245) !important;
  }
}
