.sd-darshanam-box {
  border: 1px solid #c9c9c9;
  padding: 10px 10px 12px;
  border-radius: 4px;
  margin: 15px 20px 20px 0px;
  width: fit-content;
}
.sd-darshanam-box .sd-acc-img {
  height: 100%;
  max-height: 180px;
  min-height: 180px;
  /* width: 100%; */
  border-radius: 2px;
}
.sd-darshanam-box h4 {
  color: #2f3193;
  padding: 0px 0px 10px 0px !important;
  border: none;
  float: none;
  display: inline-block;
  height: 59px;
  vertical-align: middle;
  text-align: center;
  text-transform: capitalize;
}
.sd-darshanam-box .sd-darshanam-radio [type="radio"],
input[type="radio"] {
  height: 20px;
  width: 20px;
}

.sd-darshanam-content {
  display: flex;
}

.sd-darshanam-box h4 div {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sd-darshanam-box .sd-darshanam-time {
  font-size: 16px;
  color: #454545;
  background: #e7e8fe;
  padding: 6px;
  border-radius: 4px;
  font-weight: 500;
  margin-right: 10px;
}

.sd-darshanam-box .sd-darshanam-time img {
  margin-top: -3px;
  padding-right: 10px;
}

.sd-darshanam-box .sd-darshanam-cost {
  font-size: 18px;
  color: #181818;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.sd-darshanam-box .sd-darshanam-cost img {
  height: 14px;
  margin-right: 3px;
}
@media (max-width: 440px) {
  .sd-darshanam-box {
    padding: 0px 10px 0px 10px !important;
  }
  .spec {
    flex-direction: column;
    align-items: flex-start;
  }
  .size label p {
    margin-left: 6px !important;
  }
  .sd-accept {
    top: 38px;
  }
}

@media (max-width: 600px) {
  .sd-darshanam-box {
    margin: 10px 0px !important;
    width: 100%;
  }
  .sd-darshanam-box .sd-side-heading-2 span {
    white-space: break-spaces !important;
  }
}
