.sd-color-red {
  background-color: rgb(247, 127, 127);
}
.sd-color-orange {
  background-color: rgb(245, 182, 124);
}
.accomadation_table .ant-table-row .ant-table-cell {
  padding: 5px;
}
.accomadation_table .ant-table-row:nth-child(even) {
  background-color: aliceblue;
}
.sd-light-orange {
  background-color: rgb(240, 227, 216);
}
.sd-dark-red {
  background-color: #d72020;
}
.sd-light-orange:hover .optioncss {
  color: orange;
}
.sd-light-orange:hover .optioncss {
  color: orange;
}
.sd-color-red:hover .optioncss {
  color: orange;
}
.optioncss {
  color: black;
}
.optioncss:hover {
  color: orange;
}
.ant-statistic-title {
  font-size: 12px !important;
}
.ant-statistic-content {
  font-size: 20px !important;
}

.roomCount .ant-card-body {
  padding: 2px !important;
  padding-bottom: 10px !important;
}
.color-red .ant-statistic-content .ant-statistic-content-value {
  color: red !important;
}
.sd-large .ant-statistic-content .ant-statistic-content-value {
  color: orange !important;
  font-size: 24px;
}
