.App {
  text-align: center;
  height: 100%;
  width: 100%;
}
.sd-select-seva {
  width: 250px;
}
.sd-select-dropdown-seva {
  min-width: 250px;
  max-width: 370px;
  width: 100% !important;
}
