.ant-col .ant-form-item-label {
  width: 100% !important;
  text-align: left !important;
}
.antd-custom {
  max-height: 150px;
  min-height: 150px;
  display: flex;
  justify-content: center;
}
.antd-custom .ant-card-body {
  align-items: center;
  display: flex;
}
.antd-custom:hover {
  box-shadow: 1px 10px 34px -8px !important;
  font-weight: 700;
}
.sd-block-image {
  background-repeat: no-repeat;
  background-size: cover; /* Resize the background image to cover the entire container */
  max-height: 150px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 10px;
}
.CountryRegionDropDown {
  /* : rgb(255, 255, 255, 0.3);
  background-color: black !important; */
  margin: 0px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0px;
  padding: 6.5px 11px;
  font-size: 16px;
  line-height: 1.5715;
  /* border: 1px solid rgb(67, 67, 67); */
  border-radius: 2px;
  transition: all 0.3s ease 0s;
}

/* .CountryRegionDropDown option {
  color: rgba(255, 255, 255, 0.85);
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.offline-card .ant-card-body {
  height: 420px !important;
}
.offine-inner-card {
  height: 145px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
