.antd-custom {
  max-height: 150px;
  min-height: 150px;
  display: flex;
  justify-content: center;
}
.antd-custom .ant-card-body {
  align-items: center;
  display: flex;
}
.ant-statistic {
  flex-direction: column-reverse;
  display: flex;
}
.ant-statistic-content {
  font-size: 17px !important;
  font-weight: 600;
}

.sd-select-r {
  width: 250px;
}
.sd-select-dropdown-r {
  min-width: 250px;
  max-width: 370px;
  width: 100% !important;
}
