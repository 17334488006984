.login {
  text-align: center;
  overflow: hidden;
  border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  width: 310px;
  padding: 0px !important;
  border-top: none !important;
  box-shadow: 0px 0px 72px -46px;
  .ant-carousel {
    width: 100%;
    margin-top: -5px;
    .slick-slider {
      .slick-dots {
        margin-left: -50% !important;
        .slick-active {
          margin-right: 10px !important;
          border-radius: 50% !important;
          width: 8px;
          height: 8px;
          button {
            margin: 0px;
            background-color: #f58220 !important;
          }
        }
        li {
          margin: 0 1px;
          button {
            border-radius: 50% !important;
            width: 8px;
            height: 8px;
            border: 1px solid #fff;
          }
        }
      }
    }
  }
  .ant-card-body {
    padding: 0px !important;
  }
  .outer {
    padding: 45px 30px 20px 30px;
    margin-top: 0px;
    border: 2px solid #f58220 !important;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    border-top: 0px !important;
    .flex {
      display: flex;
      flex-direction: column;
      text-align: start;
      .ant-input {
        margin-left: 0px !important;
      }
      .ant-input-group-addon:first-child {
        border-right: 0px;
      }
      .mobile-field {
        width: 100%;
        text-align: left;
        .ant-int-group {
          margin: 10px 0px !important;
        }
      }
    }
  }
  .center-logo {
    height: 120px;
    width: 120px;
    position: absolute;
    top: 23%;
    left: 31%;
    border: 2px solid #f58220;
    border-radius: 50%;
    background-color: #fff;
  }
}
.slick-slide {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
