.bookingTable {
  counter-reset: Serial; /* Set the Serial counter to 0 */
}

.bookingTable table {
  border-collapse: separate;
}

.bookingTable tr td:first-child:before {
  counter-increment: Serial; /* Increment the Serial counter */
  content: counter(Serial); /* Display the counter */
}

.report {
  width: 100%;
}

.cardDiv .contantfontsize {
  font-size: 10px;
}

.cardDiv .ant-card-body {
  padding: 15px 24px;
}

.bookingTable,
.reportTable {
  text-align: left;
  width: 100%;
  border: 1px solid black;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.bookingTable .tableData,
.bookingTable .tableData {
  border: 1px solid black;
  width: 5%;
}

.bookingTable .serialNo {
  width: 5%;
  border: 1px solid black;
}

.bookingTable .slno {
  width: 8%;
}

.bookingTable .collection {
  width: 8%;
}

.bookingTable .itemName {
  width: 30%;
  border: 1px solid black;
}

.reportTable th,
.reportTable td {
  width: 50%;
  border: 1px solid black;
}

td.itemName {
  font-size: 12px;
}

.cardDiv {
  background-color: white !important;
  color: black !important;
}

.cardDiv .headerrow,
.footerContent {
  justify-content: center !important;
  margin: auto !important;
  width: 100% !important;
  text-align: center;
  text-transform: capitalize;
}

.headerrow {
  p {
    margin-bottom: 5px;
  }
}

.cardDiv h1,
h2,
h3 {
  color: black !important;
}

.cardDiv .ant-row .boldText {
  font-weight: bold;
}

.cardDiv .ant-divider-dashed {
  border-color: black;
  border-width: 2px 0 0;
  margin: 0 !important;
}

.cardDiv .ant-divider-horizontal {
  margin-bottom: 15px !important;
}

.cardDiv .col-6-head {
  width: 49%;
  text-align: right;
  margin-right: 4px;
}

.cardDiv .col-6-body {
  width: 50%;
  text-align: left;
}

.emptyCell {
  visibility: hidden;
}

@media print {
  .sd-report-container {
    .report {
      width: 100%;
    }
    .reportFooter {
      page-break-before: always;
    }
    .bookingTable,
    .reportTable {
      text-align: left;
      width: 100%;
      border: 1px solid black;
      margin-bottom: 20px;
      text-transform: capitalize;
      font-size: 8px;
    }

    .cardDiv .contantfontsize {
      font-size: 8px;
    }

    .cardDiv .ant-card-body {
      padding: 15px 24px;
    }

    .bookingTable .tableData,
    .bookingTable .tableData {
      border: 1px solid black;
      width: 5%;
    }

    .bookingTable .serialNo {
      width: 5%;
      border: 1px solid black;
    }

    .bookingTable .slno {
      width: 8%;
    }

    .bookingTable .collection {
      width: 8%;
    }

    .bookingTable .itemName {
      width: 30%;
      border: 1px solid black;
    }

    .reportTable th,
    .reportTable td {
      width: 50%;
      border: 1px solid black;
    }

    td.itemName {
      font-size: 10px;
    }

    .cardDiv {
      background-color: white !important;
      color: black !important;
    }

    .cardDiv .headerrow,
    .footerContent {
      justify-content: center !important;
      margin: auto !important;
      width: 100% !important;
      text-align: center;
      text-transform: capitalize;
    }

    .cardDiv h1,
    h2,
    h3 {
      color: black !important;
    }

    .cardDiv .ant-row .boldText {
      font-weight: bold;
    }

    .cardDiv .ant-divider-dashed {
      border-color: black;
      border-width: 2px 0 0;
      margin: 0 !important;
    }

    .cardDiv .ant-divider-horizontal {
      margin-bottom: 15px !important;
    }

    .cardDiv .col-6-head {
      width: 49%;
      text-align: right;
      margin-right: 4px;
    }

    .cardDiv .col-6-body {
      width: 50%;
      text-align: left;
    }
  }
}
