.sevaform {
  padding-left: 20px;
  padding-right: 20px;
}
@media print {
  .sevaform {
    padding: 20px;
    box-sizing: border-box;
  }
  .t-l {
    text-align: left;
  }
}
