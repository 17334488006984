.sd-temple {
  width: 100%;
  display: flex;
  .sd-temple-left {
    width: 30%;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    .ant-picker-calendar {
      .ant-picker-panel {
        .ant-picker-body {
          .ant-picker-content {
            thead {
              tr,
              th {
                padding-bottom: 10px;
              }
            }
            tbody {
              td {
                padding: 10px;
              }
            }
          }
        }
      }
    }
    .sd-temple-bottom {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      padding: 10px;
      p {
        margin: 0px;
      }
    }
  }
  .sd-temple-right {
    margin-left: 25px;
    padding: 0px 20px;
    width: 70%;
    background-color: white;
    .sd-tabs {
      height: 100%;
      .ant-tabs {
        height: 100%;
        min-height: fit-content;
      }
      .ant-tabs-content-holder {
        height: 100%;
        min-height: fit-content;
        .ant-tabs-content {
          height: 100%;
          min-height: fit-content;
        }
      }
      .temple-calender {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        min-height: fit-content;
        .tc-top {
          width: 100%;
          height: 50px;
          display: flex;
          div {
            margin-right: 20px;
            button {
              margin: 0px;
            }
            .active-button {
              color: #f38e00;
              border: 1px solid #f38e00;
            }
          }
          button {
            margin-right: 10px;
          }
        }
        .tc-bottom {
          height: calc(100% - 50px);
          display: flex;
          width: 100%;
          margin-bottom: 20px;
          min-height: fit-content;
          .tc-left {
            height: 100%;
            margin-right: 10px;
            .sd-header {
              font-weight: 600;
            }

            h3 {
              margin-bottom: 0px !important;
              padding: 5px;
            }
            .ant-divider {
              margin: 8px 0px;
            }
          }
          .tc-right {
            height: 100%;
            margin-left: 10px;

            h3 {
              margin-bottom: 0px !important;
              padding: 5px;
            }
            .ant-divider {
              margin: 8px 0px;
            }
          }

          .tc-p-left {
            height: 100%;
            margin-right: 10px;
            padding: 10px;
            border: 1px solid #cbcbcb !important;

            .sd-header {
              font-weight: 600;
              padding: 5px 5px 15px 5px;
              border-bottom: 1px solid #cbcbcb;
              margin-bottom: 15px;
              font-size: 17px;
            }

            h3 {
              margin-bottom: 0px !important;
              padding: 5px;
            }
            .ant-divider {
              margin: 8px 0px;
            }
          }
          .tc-p-right {
            height: 100%;
            margin-right: 10px;
            padding: 10px;
            border: 1px solid #cbcbcb !important;

            .sd-header {
              font-weight: 600;
              padding: 5px 5px 15px 5px;
              border-bottom: 1px solid #cbcbcb;
              margin-bottom: 15px;
              font-size: 17px;
            }
            h3 {
              margin-bottom: 0px !important;
              padding: 5px;
            }
            .ant-divider {
              margin: 8px 0px;
            }
          }
          .tc-e-bottom {
            height: calc(100% - 50px);
            width: 100%;
            border: 1px solid #cbcbcb;
            max-height: 315px;
            min-height: 315px;
            overflow-y: scroll;
          }
          .w-50 {
            width: 50%;
          }
          .w-100 {
            width: 100%;
          }
        }
      }
      .temple-panchangam {
      }
      .temple-events {
      }
    }
  }
}
